<template>
  <fb-page>
    <!-- header -->
    <fb-header
      slot="header"
      :title="'客户列表'"
      fixed
    >
      <fb-button
        slot="left"
        icon="back"
        @click="$router.back()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <div class="case-container">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="'没有更多了'"
        @load="onLoad"
      >
        <van-cell
          v-for="(item,index) in list"
          :key="index"
          :title="item.caseName"
          :label="item.caseCreateTime | date"
        />
      </van-list>
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'

export default {
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0
    }
  },
  filters: {
    date (value) {
      const time = new Date(value)
      return `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()}`
    }
  },
  methods: {
    async onLoad () {
      this.page = this.page + 1
      // const caseBase = '//192.168.1.137:10004'
      const response = await this.$axios.get(`${caseBase}/lts/customer/queryCaseAttrBycustomerId?customerId=${this.$route.params.id}&limit=12&pageNum=${this.page}`)
      const { data, code } = response.data
      if (code === '200') {
        this.list.push(...data)
        this.loading = false
        if (data.length < 12) {
          this.finished = true
        }
        if (data.length === 0) {
          this.finished = true
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.case-container {
  padding: 10px;

  &:before {
    content: '';
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #eef7fc;
    top: 0;
    left: 0;
    z-index: -1;
  }

  & .van-cell {
    margin-bottom: 10px;
    box-shadow: 0 0 1px #40B4E9;
    overflow: hidden;
    border-radius: 5px;
  }

  & .van-cell__label {
    text-align: right;
  }
}
</style>
